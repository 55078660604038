import Repository from './repository'

const resource = '/v1/configuracoes/deficiencias'

export default {
  buscaDeficiencia (idDeficiencia) {
    return Repository.get(`${resource}/${idDeficiencia}`)
  },
  listarDeficiencias () {
    return Repository.get(`${resource}`)
  },
  salvarDeficiencia (deficiencia) {
    return Repository.post(`${resource}`, deficiencia)
  },
  atualizarDeficiencia (idDeficiencia, deficiencia) {
    return Repository.put(`${resource}/${idDeficiencia}`, deficiencia)
  },
  deletarDeficiencia (id) {
    return Repository.delete(`${resource}/${id}`)
  }
}
